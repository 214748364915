
//*****************************************************************************

"use strict";

//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";
import * as getPort from "../common/page.media.ports.js";

import { ctrStyleMode } from "../common/page.style.mode.js";
import { ctrPagePreloader } from "../common/page.preloader.js";
import { ctrHeadlineSlider } from "../common/page.headline.slider.js";
import { ctrMapOverlay } from "../common/page.map.overlay.js";
import { ctrSwitchContent } from "../common/page.switch.content.js";
import { ctrGoTopButtons } from "../common/page.gotop.buttons.js";
import { ctrStatsClient } from "../common/page.stats.client.js";

import { ctrMobileMenu } from "../common/page.mobile.menu.js";
import { ctrUserLogin } from "../common/page.user.login.js";
import { ctrUserSession } from "../common/page.user.session.js";
import { ctrImageLightbox } from "../common/page.image.lightbox.js";
import { ctrAlertBox } from "../common/page.alert.box.js";

//*****************************************************************************

let currentPage = getFunc.getCurrentPage();

//*****************************************************************************

const getSessionCookie = getFunc.getCookieName("DJKSESSID");

if(getSessionCookie) {

 window.addEventListener("load", function() {

  const arrLoginLinks = document.querySelectorAll('[data-link="/djk-login"]');
  const selectLogoutIcon = document.querySelector(".contact-right .logout");

  arrLoginLinks.forEach( elem  => {

   const getElemClass = elem.getAttribute("class");

   const li = document.createElement("li");
   if(getElemClass) li.setAttribute("class",getElemClass);
   li.setAttribute("data-link","/kursadministration");
   li.innerHTML = "Kursadministration";
   elem.parentElement.appendChild(li);

   li.addEventListener("click", function() {

    window.location.href = "/kursadministration";

   });

   elem.style.display = "none";

  });

  if(selectLogoutIcon !== null) selectLogoutIcon.classList.remove("hide");

  const setUserSession = new ctrUserSession;

  setUserSession.init();

 });

}

//*****************************************************************************

const getMenuPageLinks = document.querySelectorAll("[data-link]");

getMenuPageLinks.forEach( elem  => {

 let getDataLink = elem.getAttribute("data-link");

 if(getDataLink === "/djk-login") {

  elem.addEventListener("click", function() { getRequestLoginForm(); } );

 } else if(getDataLink === "/spenden") {

  elem.addEventListener("click", function() { closeMobileMenu(); } );

 } else {

  elem.addEventListener("click", function() {

   if(!elem.classList.contains("act")) {

    if(getDataLink === "/home") getDataLink = "/";

    window.location.href = getDataLink;

   }

  });

  if(elem.classList.contains("act")) { elem.style.cursor = "default"; }

 }

});

//*****************************************************************************

const getRequestLoginForm = function () {

 const request = new XMLHttpRequest();

 request.open("GET","./tpl/tpl.user.login.php",true);

 request.onreadystatechange = function() {

  if(request.readyState === 4) {

   if(request.status == 200) {

    let elem = document.createElement("div");
    elem.classList.add("djk-login");
    document.body.appendChild(elem);
    elem.innerHTML = request.responseText;

    const setUserLogin = new ctrUserLogin;

    setUserLogin.init();

   }

  }

 }

 request.send(null);

}

//*****************************************************************************

const closeMobileMenu = function () {

 const selectMobileMenu = document.querySelector(".mobile-menu");

 if(selectMobileMenu !== null) {

  const selectNavMenu = selectMobileMenu.querySelector(".nav-menu");

  getFunc.removePageOverflow("overlay");

  selectNavMenu.style.setProperty("right","0");
  selectNavMenu.style.setProperty("transform","translateX(100%)");

  selectNavMenu.addEventListener("transitionend", function transition() {

   selectMobileMenu.classList.remove("open");

   window.location.href = "/info#spenden";

   selectNavMenu.removeEventListener("transitionend",transition);

  });

 }

}

//*****************************************************************************

const goToTopButtons = document.querySelectorAll(".go-top");

goToTopButtons.forEach( elem  => {

 elem.addEventListener("click", event => { getFunc.scrollToTop(); } )

});

//*****************************************************************************

const srcImgList = [

 "/img/svg/mode-light-hover.svg",
 "/img/svg/mode-dark-hover.svg"

];

getFunc.preloadImages(srcImgList);

//*****************************************************************************

if(document.getElementsByClassName("mobile-menu").length) {

 const setMobileMenu = new ctrMobileMenu;

 document.querySelectorAll(".hamburger").forEach( elem  => {

  elem.addEventListener("click", () => { setMobileMenu.init(); });

 });

}

//*****************************************************************************

if(document.getElementsByClassName("plugin-lightbox").length) {

 const request = new XMLHttpRequest();

 request.open("GET","./tpl/tpl.lightbox.php",true);

 request.onreadystatechange = function() {

  if(request.readyState === 4) {

   if(request.status == 200) {

    let elem = document.createElement("div");
    elem.classList.add("lightbox");
    document.body.appendChild(elem);
    elem.innerHTML = request.responseText;

    const setImageLightbox = new ctrImageLightbox;

    setImageLightbox.init();

   }

  }

 }

 request.send(null);

}

//*****************************************************************************

//if(document.getElementsByClassName("logo-txt-top").length) {
//
// const selectClassElement = document.querySelector(".logo-txt-top");
//
// selectClassElement.addEventListener("click", function() {
//
//  const setAlertBox = new ctrAlertBox("alert");
//
//  const alertBoxInfo = setAlertBox;
//
//  alertBoxInfo.init({
//
//   setPositionTop: "15%",
//   setOverlayClick: true,
//   setClassIcon: "alert-icon-warning",
//   setHeaderText: "Openbox Alert Info",
//   setAlertText: "Ich habe die Datenschutzerklärung und den Hinweis auf die<br>Verwendung von Google Maps gelesen und akzeptiert.",
//
//   setButtons: {
//
//    setButtonRed: { posBtn:1, classBtn:"bg-red", classId:"id-1", borderBtn:1, marginBtn:0, txtBtn:"Button Red" },
//    setButtonOrange: { posBtn:2, classBtn:"bg-orange", classId:"id-2", borderBtn:0, marginBtn:0, txtBtn:"Button Orange" },
//    setButtonGray: { posBtn:3, classBtn:"bg-gray", classId:"id-3", borderBtn:0, marginBtn:0, txtBtn:"Button Gray" },
//
//   }
//
//  })
//
//  const getAlertButtonId = document.querySelector(".alert-footer .id-1");
//
//  getAlertButtonId.addEventListener("click", function() {
//
//   alertBoxInfo.closeOpenboxAlert();
//
//  });
//
// });
//
//}

//*****************************************************************************

const goPageToHeader = [

 "info","vereinsportrait","vereinssatzung","datenschutz","impressum","beitritt",
 "kontakt","eiss-siegel","stellenangebote","aktuelles","belegungsplan","partner",
 "eltern-kind-sportkurse","kinderturnen","ballschule","life-kinetik","fitness-gymnastik",
 "wanderungen","kegeln","taekwondo","volleyball","fussball","abteilung-taekwondo",
 "abteilung-breitensport","abteilung-emoki","kompetenz-emoki","kursadministration",
 "bildnachweis","schwimmen","sportschwimmen","sportspiele","konzepte","schachkurs"

];

if(goPageToHeader.indexOf(currentPage) !== -1) {

 setTimeout(() => {

  window.scrollTo({ top: 520, left: 0, behavior: "smooth" });

 }, 500);

}

//*****************************************************************************

const setMenuSelectPage = [ "/","aktuelles","impressum" ];

let setLinkData = currentPage;

if(!setLinkData) setLinkData = "/";

setMenuSelectPage.forEach ( function (lnk) {

 if(setLinkData === lnk) {

  if(setLinkData !== "/") setLinkData = "/" + setLinkData;

  let elem = document.querySelector('ul.menu > li[data-link="' + setLinkData + '"]');

  elem.style.cursor = "default";
  elem.classList.add("act");

 }

});

//*****************************************************************************

const openHeadingContent = function () {

 const classHeading = document.querySelectorAll(".heading");

 classHeading.forEach( item => {

  const classContent = item.nextElementSibling;

  if(classContent.classList.contains("close")) {

   if(!classContent.classList.contains("google-maps")) {

    item.classList.remove("close");
    classContent.classList.remove("close");
    classContent.style.height = classContent.scrollHeight + "px";

   }

  }

 });

}

//getPort.media_not_xs.addEventListener("change", (e) => {
//
// if(e.matches) { console.log("media_not_xs"); openHeadingContent(); }
//
//});
//
//if(getPort.media_not_xs.matches) { openHeadingContent(); }

//*****************************************************************************

const closeHeadingContent = function () {

 const classHeading = document.querySelectorAll(".heading");

 classHeading.forEach( item => {

  const classContent = item.nextElementSibling;

  if(!classContent.classList.contains("close")) {

   if(!classContent.classList.contains("google-maps")) {

    item.classList.add("close");
    classContent.classList.add("close");
    classContent.style.height = null;

   }

  }

 });

}

//getPort.media_xs.addEventListener("change", (e) => {
//
// if(e.matches) { closeHeadingContent(); }
//
//});
//
//if(getPort.media_xs.matches) { closeHeadingContent(); }

//*****************************************************************************
